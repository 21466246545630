<xpo-card>
  <xpo-card-header>
    <xpo-card-title>Welcome to GoFaster UI/Library Guides & Demos</xpo-card-title>
  </xpo-card-header>
  <xpo-card-content>
    <div>Please modify and use this <code>main-component</code> as your first page.</div>
    <B>
      For any supports, please contact
      <a href="mailto:LTLITServiceGovernance@xpo.com?subject=GoFaster UI Support">#LTL IT Service Governance</a>.
    </B>
  </xpo-card-content>
</xpo-card>
